@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400;6..12,500;6..12,800&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.abc {
  backdrop-filter: blur(20px) !important;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #1a3183 !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background-color: white;
}

.drawer-scrollbar::-webkit-scrollbar {
  width: 0.2em;
  height: 0.5em;
}

.drawer-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background-color: #1a3183 !important;
}
.drawer-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: white;
}

.reviews {
  background: url("/images/reviews-vector.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-origin: content-box;
  background-position: center;
}
a,
p,
.fontNew {
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 1px;
}
input,
textarea {
  outline: none;
}

.service-card-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.project-menu {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 95%;
  padding-top: 2rem;
  transform: translate(-50%, 4%);
}

@media (min-width: 1280px) {
  .collaboration {
    background: url("/images/coll_vector.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-origin: content-box;
    background-position: center;
  }
  .about {
    background: url("/images/about_illustration.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-origin: content-box;
    background-position: center;
  }
}

/* @media (min-width: 1535px) {
  .products-slider {
    padding-right: 14rem !important;
    padding-left: 14rem !important;
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
} */

#case-study-slider .swiper {
  width: 100%;
  height: 100%;
}

#case-study-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

#case-study-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#case-study-slider .swiper-pagination-bullet {
  background-color: #fa7070;
  width: 20px;
  height: 20px;
}

.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
}
.mega-box .content {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #d9d9d9;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover {
  color: #f2f2f2;
}

.input-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-appearance: none;
}

input[type="date"] {
  -webkit-appearance: none;
}

.loader {
  width: 100px;
  height: 100px;
  display: inline-block;
  position: relative;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #1a3183;
  position: absolute;
  left: 0;
  top: 0;
  animation: animloader 2s linear infinite;
}
.loader::after {
  animation-delay: 1s;
}

#menu-loader.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

#menu-loader.loader::after,
.loader::before {
  width: 50px;
  height: 50px;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.blog-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.content-container,
h3,
h4,
h5,
h6 {
  /* font-size: 1.25rem;
  line-height: 2rem; */
  /* font-family: Poppins; */
  font-family: "Nunito Sans", sans-serif;
  font-weight: bold;
}

.content-container h1{
  font-size: 2.125rem;
  line-height: 1;
  letter-spacing: -0.0625rem;
}

.content-container h2{
  font-size: 1.875rem;
  line-height: 1.067;
  letter-spacing: -0.05rem;
}

.content-container h3{
  font-size: 1.5rem;
  line-height: 1.083;
  letter-spacing: -0.0375rem;
}

.content-container h4{
  font-size: 1.25rem;
  line-height: 1.1;
  letter-spacing: -0.025rem;
}

.content-container h5{
  font-size: 1.125rem;
  line-height: 1.111;
  letter-spacing: -0.0125rem;
}
.content-container h6{
  font-size: 1rem;
  line-height: 1.125;
  letter-spacing: -0.00625rem;
}

.content-container p {
  font-size: 1rem;
  line-height: 2rem;
  /* font-family: Poppins; */
  font-family: "Nunito San", sans-serif;
  font-weight: 400;
}
div {
  font-family: "Nunito Sans", sans-serif;
}
@media (min-width: 768px) {
  .home-container {
    display: grid;
    grid-template-columns: 1.7fr 1fr;
    gap: 1rem;
  }
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid rgba(22, 52, 159);
  width: 10rem;
  height: 4rem;
  border-radius: 999px;
  animation: pulse 1.5s linear infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid rgba(22, 52, 159);
  width: 10rem;
  height: 4rem;
  border-radius: 999px;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}
.pulsewp::before {
  content: "";
  position: absolute;
  border: 1px solid #4fe666;
  width: 55px;
  height: 55px;
  border-radius: 999px;
  animation: pulse 1.5s linear infinite;
}

.pulsewp::after {
  content: "";
  position: absolute;
  border: 1px solid #57f06d;
  width: 55px;
  height: 55px;
  border-radius: 999px;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}

.pulsefi::before {
  content: "";
  position: absolute;
  border: 1px solid #4fe666;
  width: 55px;
  height: 55px;
  border-radius: 999px;
  animation: pulse 1.5s linear infinite;
}

.pulsefi::after {
  content: "";
  position: absolute;
  border: 1px solid #57f06d;
  width: 65px;
  height: 65px;
  border-radius: 999px;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}
.position_fi{
  bottom: 180px !important;
  position: fixed !important;
  right: 2.7rem !important;

}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@keyframes pulsewp {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}


#scroll-container {
  height: 105px;
  overflow: hidden;
}

#scroll-text {
  height: 100%;
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 5s linear infinite;
  -webkit-animation: my-animation 5s linear infinite;
  animation: my-animation 5s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.hover-animation:hover {
  scale: 1.1;
}
